import { ArrowBackIosNewOutlined, Dashboard, Groups, LocationCityOutlined, LoginOutlined, MenuOutlined, OnDeviceTraining, PrecisionManufacturing, Summarize, VaccinesOutlined } from '@mui/icons-material'
import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar'
import { useLocation, useNavigate } from 'react-router-dom'
import { UseAuth } from '../customhooks/UseAuth'

const Items = ({ label, icon, color, onClickAction, active }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <Tooltip title={label} placement="right-start">
            <div>
                <MenuItem
                    active={active}
                    style={{
                        // color: color,
                        // background: active ? '#f0f0f0' : (isHovered ? '#f0f0f0' : 'white'),// Change background color on hover
                        color: active ? '#43E2B7' : (isHovered ? '#43E2B7' : 'black'),
                        borderStartStartRadius: 20,
                        borderEndStartRadius: 20,
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    icon={icon}
                    onClick={onClickAction}
                >
                    <Typography>{label}</Typography>
                </MenuItem>
            </div>
        </Tooltip>
    )
}

const SideBarComponent = () => {
    const { roleId } = UseAuth();
    const [show, setHide] = useState(false)
    const [activeItem, setActiveItem] = useState('');
    const navigate = useNavigate()
    const location = useLocation();

    const handleNavigation = (path) => {
        navigate(path)
        setActiveItem(path); // Update the active item when navigating
    }

    useEffect(() => {
        // Extract the pathname from location object and set the active item accordingly
        setActiveItem(location.pathname);
    }, [location]);

    const logOutHandler = () => {
        navigate('/')
        localStorage.clear('samyojak')
    }
    return (
        <>
            <Box>
                <Sidebar collapsed={show}
                    transitionDuration={500}
                    backgroundColor={"white"}
                    rootStyles={{
                        height: "100vh",
                        boxShadow: "0 3px 3em #f0f0f0"
                    }}>
                    {!show && <Box sx={{ textAlign: "right", p: 1.5, cursor: "pointer", position: "sticky", top: "0em", bgcolor: "white", zIndex: 99 }}>
                        <ArrowBackIosNewOutlined onClick={() => setHide(true)} />
                    </Box>}
                    {show && <Box sx={{ textAlign: "center", p: 1.5, cursor: "pointer", position: "sticky", top: "0em", bgcolor: "white", zIndex: 99 }}>
                        <MenuOutlined onClick={() => setHide(false)} />
                    </Box>}
                    {!show && <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1em", p: 1, position: "sticky", top: "3em", zIndex: 99, bgcolor: "white" }}>
                        <Avatar style={{ width: 70, height: 70, marginTop: "-2em", boxShadow: "0 0 5px black" }} src='/samyojak_logo.jpeg' alt='samyojak_logo.jpeg' />
                        <img src="/logo_2.jpeg" alt="logo_2.jpeg" style={{ width: "auto", height: 70, marginTop: "0.5em", border: "2px lightgrey solid", padding: "0 0.3em" }} />
                    </Box>}
                    <Menu
                        closeOnClick
                        transitionDuration={500}
                    >
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (<Items label={'Dashboard'} icon={<Dashboard size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak')} active={activeItem === '/samyojak'} />
                        )}
                        {(roleId === 6000 || roleId === 7000) && (<Items label={'Dashboard'} icon={<Dashboard size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak/oil-vendor-manufacture-company-dashboard')} active={activeItem === '/samyojak/oil-vendor-manufacture-company-dashboard'} />
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <Items label={'Manage Users'} icon={<Groups size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak/users')} active={activeItem === '/samyojak/users'} />
                        )}
                        {/* <SubMenu icon={<SettingsInputComponentOutlined />} label="Manage Customers"> */}
                        {/* <Items label={'Super Admin'} icon={<LocationCityOutlined size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak/companyadmin')} active={activeItem === '/samyojak/companyadmin'} /> */}
                        {/* <Items label={'Machine Admin'} icon={<Yard size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak/machineadmin')} />
                        {/* </SubMenu> */}

                        {(roleId === 6000) && (
                            <Items label={'Oil Vendor'} icon={<PrecisionManufacturing size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak/oilvender')} active={activeItem === '/samyojak/oilvender'} />
                        )}
                        {/* <Items label={'Machine Admin'} icon={<Yard size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak/machineadmin')} /> */}


                        {(roleId === 7000) && (
                            <Items label={'Oil Manufacturer'} icon={<VaccinesOutlined size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak/oilmanufacturer')} active={activeItem === '/samyojak/oilmanufacturer'} />
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <SubMenu icon={<LocationCityOutlined />} label="Setup">
                                <Box padding={"0 0 0 1.5em"}>
                                    {(roleId === 2000 || roleId === 3000) && (
                                        <Items label={'Company'}
                                            // icon={<LocationCityOutlined size={25} />} 
                                            color={"black"} onClickAction={() => handleNavigation('/samyojak/managecomapny')} active={activeItem === '/samyojak/managecomapny'} />
                                    )}
                                    {(roleId === 2000 || roleId === 3000 || roleId === 4000) && (
                                        <Items label={'Plants'}
                                            // icon={<Yard size={25} />}
                                            color={"black"} onClickAction={() => handleNavigation('/samyojak/manageplant')} active={activeItem === '/samyojak/manageplant'} />
                                    )}
                                    {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                                        <Items label={'Machine'}
                                            // icon={<PrecisionManufacturing size={25} />}
                                            color={"black"} onClickAction={() => handleNavigation('/samyojak/managemachine')} active={activeItem === '/samyojak/managemachine'} />
                                    )}
                                    <Items label={'Device'}
                                        //  icon={<VaccinesOutlined size={25} />} 
                                        color={"black"} onClickAction={() => handleNavigation('/samyojak/managedevice')} active={activeItem === '/samyojak/managedevice'} />
                                </Box>
                            </SubMenu>
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <SubMenu icon={<OnDeviceTraining />} label="Manage Device Data">
                                <Box padding={"0 0 0 1.5em"}>
                                    <Items label={'Device Raw Data'}
                                        //  icon={<VaccinesOutlined size={25} />} 
                                        color={"black"} onClickAction={() => handleNavigation('/samyojak/devicerawdata')} active={activeItem === '/samyojak/devicerawdata'} />
                                    <Items label={'Device Process Data'}
                                        //  icon={<VaccinesOutlined size={25} />} 
                                        color={"black"} onClickAction={() => handleNavigation('/samyojak/deviceproccessseddata')} active={activeItem === '/samyojak/deviceproccessseddata'} />
                                </Box>
                            </SubMenu>
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <SubMenu icon={<Summarize />} label="Manage Reports">
                                <Box padding={"0 0 0 1.5em"}>
                                    <Items label={'Summary Reports'}
                                        // icon={<Assignment size={25} />} 
                                        color={"black"} onClickAction={() => handleNavigation('/samyojak/reports')} active={activeItem === '/samyojak/reports'} />
                                    <Items label={'Detailed Reports'}
                                        //  icon={<Assignment size={25} />}
                                        color={"black"} onClickAction={() => handleNavigation('/samyojak/detailedreports')} active={activeItem === '/samyojak/detailedreports'} />
                                </Box>
                            </SubMenu>
                        )}
                        {/* {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <Items label={'Notification'} icon={<Notifications size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak/notifications')} active={activeItem === '/samyojak/notifications'} />
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <Items label={'Settings'} icon={<Settings size={25} />} color={"black"} onClickAction={() => handleNavigation('/samyojak/settings')} active={activeItem === '/samyojak/settings'} />
                        )} */}
                        <Items label={'Log Out'} icon={<LoginOutlined size={25} />} color={"black"} onClickAction={() => logOutHandler()} />
                    </Menu>

                </Sidebar>
            </Box>
        </>
    )
}

export default SideBarComponent
