import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import SideBarComponent from './components/SideBarComponent';
import TopBarComponent from './components/TopBarComponent';
import { Suspense, lazy } from 'react';
import AuthGuard from './components/authGuard/AuthGuard';
const LoginComponent = lazy(() => import('./components/login/LoginComponent'));
const EmailValidation = lazy(() => import('./components/forgot-password/EmailValidation'));
const OtpValidation = lazy(() => import('./components/forgot-password/OtpValidation'));
const ForgotPassword = lazy(() => import('./components/forgot-password/ForgotPassword'));

const DashBoardComponent = lazy(() => import('./components/dashboard/DashBoardComponent'));
const PlantDashboardComponent = lazy(() => import('./components/dashboard/PlantDashboard'));
const MachineDashboardComponent = lazy(() => import('./components/dashboard/MachineDashboard'));
const OilVanderAndManufacturerCompanyDashboard = lazy(() => import('./components/dashboard/OilVendorAndManufactureCompanyDashboard'));
const OilVanderAndManufacturerPlantDashboard = lazy(() => import('./components/dashboard/OilVenderAndManufacturePlantdashboard'));
const OilVanderAndManufacturerMachineDashboard = lazy(() => import('./components/dashboard/OilVanderAndManufacturerDashboard'));
const DeviceDashboardComponent = lazy(() => import('./components/dashboard/DeviceDashboard'));
const DeviceStatusDashboardComponent = lazy(() => import('./components/dashboard/DeviceStatusDashboard'));



const CompanyAdminComponent = lazy(() => import('./components/manageUsers/CompanyAdmin'));
const AddCompanyAdmin = lazy(() => import('./components/manageUsers/AddCompanyAdmin'));
const UpdateCompanyAdmin = lazy(() => import('./components/manageUsers/UpdateCompanyAdmin'));

const MachinAdminComponent = lazy(() => import('./components/machinAdmin/MachinAdmin'));

const OilVenderComponent = lazy(() => import('./components/oilVender/OilVender'));
const OilVendorViewComponent = lazy(() => import('./components/oilVender/OilVendorView'));


const OilManufacturerComponent = lazy(() => import('./components/oilManufacturer/OilManufacturer'));

const ManageComapanyComponent = lazy(() => import('./components/manageCompany/ManageComapany'));
const AddCompanyComponent = lazy(() => import('./components/manageCompany/AddCompany'));
const UpdateCompanyComponent = lazy(() => import('./components/manageCompany/UpdateCompany'));

const ManagePlantsComponent = lazy(() => import('./components/managePlants/ManagePlants'));
const AddPlantComponent = lazy(() => import('./components/managePlants/AddPlant'));
const UpdatePlantComponent = lazy(() => import('./components/managePlants/UpdatePlant'));

const ManageMachineComponent = lazy(() => import('./components/manageMachine/ManageMachine'));
const AddMachineComponent = lazy(() => import('./components/manageMachine/AddMachine'));
const UpdateMachineComponent = lazy(() => import('./components/manageMachine/UpdateMachine'));

const ManageDeviceComponent = lazy(() => import('./components/manageDevice/ManageDevice'));
const AddDeviceComponent = lazy(() => import('./components/manageDevice/AddDevice'));
const UpdateDeviceComponent = lazy(() => import('./components/manageDevice/UpdateDevice'));

const DeviceRawDataComponent = lazy(() => import('./components/deviceRawData/DeviceRawData'));
const DeviceProccessedDataComponent = lazy(() => import('./components/deviceProccessedData/DeviceProccessedData'));


const ReportsComponent = lazy(() => import('./components/reports/Reports'));
const DetailedReportComponent = lazy(() => import('./components/reports/DetailedReport'));

const NotificationsComponent = lazy(() => import('./components/notifications/NotificationsComponent'));

const SettingsComponent = lazy(() => import('./components/settings/Settings'));

function App() {

  const location = useLocation();
  const isLoginPage = location.pathname === "/" || location.pathname === "/forgot-password" || location.pathname === "/email-validation" || location.pathname === "/otp-validation";

  return (
    <>
      <div className="app">
        {!isLoginPage && <SideBarComponent />}
        <main className='content'>
          {!isLoginPage && <TopBarComponent />}
          <Routes>
            <Route path='/'>
              <Route index element={<Suspense fallback={<div>Loading..</div>}><LoginComponent /></Suspense>} />
              <Route path='email-validation' element={<Suspense fallback={<div>Loading..</div>}><EmailValidation /></Suspense>} />
              <Route path='otp-validation' element={<Suspense fallback={<div>Loading..</div>}><OtpValidation /></Suspense>} />
              <Route path='forgot-password' element={<Suspense fallback={<div>Loading..</div>}><ForgotPassword /></Suspense>} />
            </Route>
            <Route path='/samyojak'>
              <Route index element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><DashBoardComponent /></AuthGuard></Suspense>} />
              <Route path='plants-dashboard/:cid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><PlantDashboardComponent /></AuthGuard></Suspense>} />
              <Route path='oil-vendor-manufacture-company-dashboard' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><OilVanderAndManufacturerCompanyDashboard /></AuthGuard></Suspense>} />
              <Route path='oil-vendor-manufacture-plant-dashboard/:cid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><OilVanderAndManufacturerPlantDashboard /></AuthGuard></Suspense>} />
              <Route path='oil-vendor-manufacture-machine-dashboard/:pid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><OilVanderAndManufacturerMachineDashboard /></AuthGuard></Suspense>} />
              <Route path='machine-dashboard/:pid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><MachineDashboardComponent /></AuthGuard></Suspense>} />
              <Route path='device-dashboard/:mid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><DeviceDashboardComponent /></AuthGuard></Suspense>} />
              <Route path='device-status/:mid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><DeviceStatusDashboardComponent /></AuthGuard></Suspense>} />

              <Route path='users' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><CompanyAdminComponent /></AuthGuard></Suspense>} />
              <Route path='addusers' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><AddCompanyAdmin /></AuthGuard></Suspense>} />
              <Route path='updateusers/:cid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><UpdateCompanyAdmin /></AuthGuard></Suspense>} />
              <Route path='machineadmin' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><MachinAdminComponent /></AuthGuard></Suspense>} />


              <Route path='oilvender' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><OilVenderComponent /></AuthGuard></Suspense>} />
              <Route path='oilvender/:ovid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><OilVendorViewComponent /></AuthGuard></Suspense>} />


              <Route path='oilmanufacturer' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><OilManufacturerComponent /></AuthGuard></Suspense>} />

              <Route path='managecomapny' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><ManageComapanyComponent /></AuthGuard></Suspense>} />
              <Route path='addcomapny' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><AddCompanyComponent /></AuthGuard></Suspense>} />
              <Route path='updatecomapny/:cid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><UpdateCompanyComponent /></AuthGuard></Suspense>} />

              <Route path='manageplant' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><ManagePlantsComponent /></AuthGuard></Suspense>} />
              <Route path='addplant' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><AddPlantComponent /></AuthGuard></Suspense>} />
              <Route path='updateplant/:pid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><UpdatePlantComponent /></AuthGuard></Suspense>} />

              <Route path='managemachine' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><ManageMachineComponent /></AuthGuard></Suspense>} />
              <Route path='addmachine' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><AddMachineComponent /></AuthGuard></Suspense>} />
              <Route path='updatemachine/:mid' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><UpdateMachineComponent /></AuthGuard></Suspense>} />

              <Route path='managedevice' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><ManageDeviceComponent /></AuthGuard></Suspense>} />
              <Route path='adddevice' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><AddDeviceComponent /></AuthGuard></Suspense>} />
              <Route path='updatedevice/:did' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><UpdateDeviceComponent /></AuthGuard></Suspense>} />

              <Route path='devicerawdata' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><DeviceRawDataComponent /></AuthGuard></Suspense>} />
              <Route path='deviceproccessseddata' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><DeviceProccessedDataComponent /></AuthGuard></Suspense>} />


              <Route path='reports' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><ReportsComponent /></AuthGuard></Suspense>} />
              <Route path='detailedreports' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><DetailedReportComponent /></AuthGuard></Suspense>} />


              <Route path='notifications' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><NotificationsComponent /></AuthGuard></Suspense>} />

              <Route path='settings' element={<Suspense fallback={<div>Loading..</div>}><AuthGuard><SettingsComponent /></AuthGuard></Suspense>} />
            </Route>
            <Route path='*' element={<div>Page Not Found..</div>} />
          </Routes>
        </main>
      </div>
    </>
  );
}

export default App;
